<template>
  
 <div style="background: linear-gradient(-35deg, #670060, #00083f);min-height: 100vh;" >
  <b-navbar type="light" variant="light" class="p-0">
    <b-navbar-nav class="align-items-center justify-content-between col-12">
      <div class="col-lg-2 col-6">
        <b-nav-item href="#"><img src="../../public/img/geotech-logo1.png" alt="" style="width:50%;" ></b-nav-item>
      </div>
      <div>
        <b-dropdown id="dropdown-1" dropleft  class="m-md-2">
          <template #button-content>
            <i class="fa-solid fa-user"></i>
          </template>
          <b-dropdown-item v-b-modal.modal-profile>Profile</b-dropdown-item>
          <b-dropdown-item @click="Logout"><span style="color:red">Log out</span></b-dropdown-item>
        </b-dropdown>
      </div>

      <!-- Navbar dropdowns -->
      <!--<b-nav-item-dropdown text="Lang" right>
        <b-dropdown-item href="#">EN</b-dropdown-item>
        <b-dropdown-item href="#">ES</b-dropdown-item>
        <b-dropdown-item href="#">RU</b-dropdown-item>
        <b-dropdown-item href="#">FA</b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown text="User" right>
        <b-dropdown-item href="#">Account</b-dropdown-item>
        <b-dropdown-item href="#">Settings</b-dropdown-item>
      </b-nav-item-dropdown>-->
    </b-navbar-nav>
  </b-navbar>
  
  <div class="row p-3 m-0">
  <div class="col-12 col-lg-3 col-md-6 p-1 mb-3">
    <div class="card-geot shadow p-2" v-if="Incidentes_list!==null">
      <div class="p-2 d-flex align-items-center justify-content-between"> 
        <div class="d-flex align-items-center"> 
          <h5  class="m-0"><i class="fa-solid fa-list-ul"></i> Active Incident</h5> 
          <i class="fa-solid fa-circle-plus px-2" style="cursor: pointer;color: #670060;font-size:20px" @click="addIncident" ></i>
        </div>
        <div class="d-flex align-items-center">
          <div style="position: relative;" >
            <i class="fa-solid fa-bell pr-2" style="font-size:20px"></i>
        
            <div class="px-2" style="position: absolute;top:-8px;left:-13px;background:red;border-radius:20px;color:white;font-weight:700;font-size:11px" v-if="notification_num">
              {{ notification_num }}
            </div>
          </div>
          <i v-b-toggle.collapse-1 class="fa-solid fa-up-down" style="font-size:20px"></i>
        </div>
      </div>
      <b-collapse visible id="collapse-1">

      <draggable v-model="Incidentes_list" v-bind="dragOptions"  :group="{ pull: false, put: false }"  class="card-geot"  @change="moveItem($event,1)" :draggable="false">
          <div class="card-geot-element mt-2 shadow-sm" v-for="(element,index) in Incidentes_list" :key="index+'Incidentes_list'" @click="getInfo(element)">
            <div class="row align-items-center ">
              
              <div class="col-12">
                <div class="d-flex mb-3">
                  <div style="border: 5px solid red;border-radius: 10px;width:50px;background-color: red;" v-if="element.incident_status==0"></div>
                  <div style="border: 5px solid green;border-radius: 10px;width:50px;background-color: green;" v-if="element.incident_status==1"></div>
                  <strong v-if="element.incident_acknowledge_user && element.incident_status==1" class="pl-1 " style="font-size: 12px;"> Acknowledge by: {{ element.incident_acknowledge_user.first_name }} {{ element.incident_acknowledge_user.last_name }} ({{ new Date(element.incident_type_acknowledged_time).toLocaleString("en-US", {timeZone: "Europe/Athens"})}})</strong>
                </div>
                <div>
                 <strong class="text-info">#{{ element.incident_id }}</strong>  {{element.incident_cat_title.category_description}}
                </div>
              </div>
              <div class="col-12 mt-2" style="font-size: 14px;" v-if="element.count_exp_time" >
                <strong v-if="element.incident_acknowledge_user"  style="font-size: 12px;">Admission time: {{ new Date(element.incident_start_time).toLocaleString("en-US", {timeZone: "Europe/Athens"}) }}</strong>
              </div>
              <div class="col-12 mt-2 row" style="font-size: 14px;" v-if="element.count_exp_time" >
                <div class="col-6" style="color:#e50000"><i class="fa-regular fa-clock pr-2"></i>{{element.count_exp_time}}</div>
                <div class="col-6"><i class="fa-regular fa-user pr-2"></i>{{element.user.first_name}} {{element.user.last_name}}</div>
              </div>
              
            </div>
          </div>
         
      </draggable>
      </b-collapse>
    </div>
  </div>
    <div class="col-12 col-lg-3 col-md-6 p-1 mb-3">
      <div class="card-geot shadow p-2">
      
      <div class="p-2 d-flex align-items-center justify-content-between"> 
        <div class=" d-flex align-items-center"> 
        <h5  class="m-0"><i class="fa-solid fa-gears"></i> On going</h5> 
        <div class="bg-primary px-2 rounded-pill ml-2 text-white" style="font-weight:800;">{{ ongoing_list.length }}</div>
        </div>
        <div>
          <i v-b-toggle.collapse-2 class="fa-solid fa-up-down" style="font-size:20px"></i>
        </div>
      </div>
      <b-collapse visible id="collapse-2">

        <draggable v-model="ongoing_list" v-bind="dragOptions"  :group="{ pull: false, put: false }"  @change="moveItem($event,2)" :draggable="false">
          <div class="card-geot-element mt-2 shadow-sm" v-for="(element,index) in ongoing_list" :key="index+'Incidentes_list'" @click="getInfo(element)">
            <div class="row">
              
              <div class="col-12">
                <div class="d-flex mb-3">
                  
                  <strong v-if="element.incident_acknowledge_user && element.incident_status==1" class="pl-1 " style="font-size: 12px;"> Acknowledge by: {{ element.incident_acknowledge_user.first_name }} {{ element.incident_acknowledge_user.last_name }} ({{ new Date(element.incident_type_acknowledged_time).toLocaleString("en-US", {timeZone: "Europe/Athens"})}})</strong>
                </div>
                <strong class="text-info">#{{ element.incident_id }}</strong> {{element.incident_cat_title.category_description}}
               
              </div>
              <div class="col-12 mt-2" style="font-size: 14px;" v-if="element.count_exp_time" >
                <strong v-if="element.incident_acknowledge_user"  style="font-size: 12px;">Admission time: {{ new Date(element.incident_start_time).toLocaleString("en-US", {timeZone: "Europe/Athens"}) }}</strong>
              </div>
              <div class="col-12 mt-2 row" style="font-size: 14px;" v-if="element.count_exp_time" >
                <div class="col-6" style="color:#e50000"><i class="fa-regular fa-clock pr-2"></i>{{element.count_exp_time}}</div>
                <div class="col-6"><i class="fa-regular fa-user pr-2"></i>{{element.user.first_name}} {{element.user.last_name}}</div>
              </div>
            </div>
          </div>
         
        
        </draggable>
      </b-collapse>
      </div>
    </div>
    <div class="col-12 col-lg-3 col-md-6 p-1  mb-3">
      <div class="card-geot shadow p-2">
      <div class="p-2 d-flex align-items-center justify-content-between"> 
        <div class=" d-flex align-items-center"> 
          <h5  class="m-0"><i class="fa-solid fa-clipboard-check"></i> Finished</h5> 
      
        </div>
        <div>
          <i class="fa-solid fa-eye mr-2" title="View All" style="font-size:20px;cursor: pointer;" @click="ViewAllFinished" ></i>
          <i v-b-toggle.collapse-3 class="fa-solid fa-up-down" style="font-size:20px"></i>
        </div>
      </div>
      <b-collapse id="collapse-3">
        <draggable v-model="finished_list" v-bind="dragOptions_finished" :group="{ pull: false, put: false }"  @start="dragging = false" @end="dragging = false" @change="moveItem($event,3)" :draggable="false">
          <div class="card-geot-element mt-2 shadow-sm" v-for="(element,index) in finished_list" :key="index+'Incidentes_list'" @click="getInfo(element)">
            <div class="row">
              
              <div class="col-12">
                <div class="d-flex mb-3">
                  
                  <strong v-if="element.incident_acknowledge_user && element.incident_status==1" class="pl-1 " style="font-size: 12px;"> Acknowledge by: {{ element.incident_acknowledge_user.first_name }} {{ element.incident_acknowledge_user.last_name }} ({{ new Date(element.incident_type_acknowledged_time).toLocaleString("en-US", {timeZone: "Europe/Athens"})}})</strong>
                </div>
                <strong class="text-info">#{{ element.incident_id }}</strong> 
                {{element.incident_cat_title.category_description}}
              </div>
              <div class="col-12 mt-2" style="font-size: 14px;" >
                <strong v-if="element.incident_start_time"  style="font-size: 12px;">Admission time: {{ new Date(element.incident_start_time).toLocaleString("en-US", {timeZone: "Europe/Athens"}) }}</strong>
              </div>
              <div class="col-12 mt-2 row" style="font-size: 14px;" v-if="element.incident_finished_time" >
                <div class="col-6" style="color:#000fe5"><i class="fa-regular fa-clock pr-2"></i>{{ new Date(element.incident_finished_time).toLocaleString("en-US", {timeZone: "Europe/Athens"})}}</div>
                <div class="col-6"><i class="fa-regular fa-user pr-2"></i>{{element.user.first_name}} {{element.user.last_name}}</div>
              </div>
            </div>
          </div>
         
        
        </draggable>
      </b-collapse>
      </div>
    </div>
    <div class="col-12 col-lg-3 col-md-6 p-1  mb-3" v-if="clicked_Incident && !isMobile">
      <div class="card-geot shadow ">
        <div class="text-center text-light p-3" style="background-color: #0033e1;border-top-right-radius: 8px;border-top-left-radius: 8px;"> 
          <h5 class="m-0">Info</h5> 
        </div>
        <div class="p-4">
            <div>
              <div class="w-100 text-center"><strong class="text-info">#{{ clicked_Incident.incident_id }}</strong></div>
              
              <h6>Title</h6>
              <div v-if="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id" class="p-2 shadow-sm" style="background-color: #e9ecef;border-radius: 5px;">
                  {{ clicked_Incident.incident_cat_title.category_description }}
                  
              </div>
              <div v-else>
                <b-form-select v-model="clicked_Incident.incident_cat_title.category_id" class="mb-1 shadow-sm" @change="changeTitle">
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="undefined" disabled>-- Please select an option --</b-form-select-option>
                </template>

                <!-- These options will appear after the ones from 'options' prop -->
                <b-form-select-option  v-for="(item,index) in luts.lut_incidents_categories" :key="index+'lut_incidents_categories'" :value="item.category_id" >({{ item.category_type  }}){{ item.category_description}} </b-form-select-option>
               
              </b-form-select>
              </div>
             
            </div>
            <div class="mt-2" v-if="clicked_Incident.incident_cat_title.category_id===1 || clicked_Incident.incident_cat_title.category_id===7 || clicked_Incident.incident_cat_title.category_id===8 || clicked_Incident.incident_cat_title.category_id===10">
  
              <h6>Reference Station</h6>
             
                <b-form-select v-model="clicked_Incident.reference_station_id" class="mb-1 shadow-sm" :disabled="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id">
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="undefined" disabled>-- Please select an option --</b-form-select-option>
                </template>

                <!-- These options will appear after the ones from 'options' prop -->
                <b-form-select-option  v-for="(item,index) in luts.reference_stations" :key="index+'reference_stations'" :value="item.rf_code" >{{ item.rf_code_descr}} ({{ item.rf_location }})</b-form-select-option>
               
              </b-form-select>
              
            </div>
            <div class="mt-2">
              <h6>Description</h6>
              <div v-if="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id" class="p-2 shadow-sm" style="background-color: #e9ecef;border-radius: 5px;">
                  {{ clicked_Incident.incident_description }}
              </div>
              <div v-else>
              <b-form-textarea
                id="textarea"
                v-model="clicked_Incident.incident_description"
                placeholder="Description"
                size="sm"
                class="shadow-sm"
              ></b-form-textarea>
              </div>
            </div>
            <div class="mt-2">
              <h6>Type</h6>
              
              <b-form-select v-model="clicked_Incident.incident_type" class="mb-1 shadow-sm" :disabled="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id">
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="undefined" disabled>-- Please select an option --</b-form-select-option>
                </template>

                <!-- These options will appear after the ones from 'options' prop -->
                
                  <b-form-select-option v-for="(item,index) in filter_incidents_type_expiratrion" :key="index+'incidents_type_expiratrion'"  :value="item.type_id"  >{{ item.type_description}} ({{ parseInt(item.type_expiration_minutes /60) }} hours)</b-form-select-option>
              
              </b-form-select>
             
            </div>
            <div class="mt-2 ">
              <div class="d-flex align-items-center">
              <h6 class="m-0 pr-2">Acknowledged:</h6>
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="acknowledged_status"
                  name="checkbox-1"
                  :value="1"
                  :unchecked-value="0"
                  :disabled="this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0"></b-form-checkbox>
              <div v-if="clicked_Incident.incident_acknowledge_user">({{ clicked_Incident.incident_acknowledge_user.first_name }} {{ clicked_Incident.incident_acknowledge_user.last_name }})</div>
                
              </div>
              <div class="d-flex align-items-center">
                <b-form-input class="col-6 shadow-sm" :type="'date'" v-model="acknowledged_date" :disabled="!acknowledged_status ||  (clicked_Incident.incident_type_acknowledged_time ? true:false )"></b-form-input>
                <b-form-input class="col-6 shadow-sm" :type="'time'" v-model="acknowledged_time" :disabled="!acknowledged_status || (clicked_Incident.incident_type_acknowledged_time ? true:false )"></b-form-input>
              </div>
            </div>
            <div class="mt-3 d-flex justify-content-end ">
              <b-button block variant="primary" @click="saveInfo" v-if="this.clicked_Incident.incident_status===1 || this.clicked_Incident.incident_status===0"><i class="fa-solid fa-floppy-disk"></i> Save</b-button>
            </div>
            <div class="mt-3 " v-if="clicked_Incident.incident_status!==0">
              <div class="d-flex align-items-center mb-1">
                <h6 class="m-0 pr-2">Actions:</h6>
              </div>
              <div>
                  <div class="row m-0 mb-3" v-if="this.clicked_Incident.incident_status!==3">
                    <div class="col-1 p-0 ">
                        <div class="d-flex justify-content-end">
                          <div class="rounded-pill p-2 text-center shadow-sm" style="background-color: #45baff;">
                              <h6 class="m-0 font-weight-bold">{{arxika}}</h6> 
                          </div>
                        </div>
                    </div>
                    <div class="col-11 p-0 pl-2 d-flex">
                      <b-form-textarea
                        id="textarea-small"
                        size="sm"
                        placeholder="Write an action..."
                        class="shadow-sm"
                        v-model="action_comment"
                      ></b-form-textarea>
                      <b-button class="shadow-sm" style="font-size:20px" variant="primary" size="sm" :disabled="!action_comment" title="Save" @click="saveAction">></b-button>
                    </div>
                  </div>
                  <div class="row m-0 mb-3" v-for="(action,index) in actions_list" :key="index+'action'">
                    <div class="col-1 p-0 ">
                        <div class="d-flex justify-content-end">
                          <div class="rounded-pill p-2 text-center shadow-sm" style="background-color: #45baff;">
                              <h6 class="m-0 font-weight-bold">{{action.user.first_name.charAt(0).toUpperCase()+""+action.user.last_name.charAt(0).toUpperCase()}}</h6> 
                          </div>
                        </div>
                    </div>
                    <div class="col-11 p-0 pl-2">
                      <div>
                      <p class="m-0" style="font-size: 12px;">
                        <strong>{{ action.user.first_name }} {{ action.user.last_name }}</strong>  {{ new Date(action.action_time).toLocaleString("en-US", {timeZone: "Europe/Athens"})}}
                      </p>
                      <div class="d-flex">
                        <div class="shadow-sm w-100 p-2 " style="background-color: #e9ecef;border-radius: 5px;">{{action.action_description}}</div>
                      
                      </div>
                    </div>
                     
                    </div>
                  </div>
              </div>
            </div>
            <div class="mt-3" v-if="clicked_Incident.incident_status===2">
             <!-- <b-button class="shadow-sm" block variant="success" title="Save" @click="moveItem(3)"><i class="fa-solid fa-check px-2"></i>Finished</b-button>-->
              <b-form-checkbox
                id="checkbox-55"
                v-model="finished_checkbox"
                name="checkbox-55"
                @change="finished_checkbox ? moveItem(3) : null"
                size="lg"
              >
              Close Incident
              </b-form-checkbox>
            </div>

        </div>
      </div>
    </div>
    <b-modal ref="my-modal" hide-footer  title="Info" v-if="isMobile">
      <div v-if="clicked_Incident">
       
        <div >
            <div>
              <div class="w-100 text-center"><strong class="text-info">#{{ clicked_Incident.incident_id }}</strong></div>
              
              <h6>Title</h6>
              <div v-if="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id" class="p-2 shadow-sm" style="background-color: #e9ecef;border-radius: 5px;">
                  {{ clicked_Incident.incident_cat_title.category_description }}
                  
              </div>
              <div v-else>
                <b-form-select v-model="clicked_Incident.incident_cat_title.category_id" class="mb-1 shadow-sm">
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="undefined" disabled>-- Please select an option --</b-form-select-option>
                </template>

                <!-- These options will appear after the ones from 'options' prop -->
                <b-form-select-option  v-for="(item,index) in luts.lut_incidents_categories" :key="index+'lut_incidents_categories'" :value="item.category_id" >{{ item.category_description}}</b-form-select-option>
               
              </b-form-select>
              </div>
             
            </div>
            <div class="mt-2">
              
              <h6>Reference Station</h6>
            
                <b-form-select v-model="clicked_Incident.reference_station_id" class="mb-1 shadow-sm" :disabled="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id">
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="undefined" disabled>-- Please select an option --</b-form-select-option>
                </template>

                <!-- These options will appear after the ones from 'options' prop -->
                <b-form-select-option  v-for="(item,index) in luts.reference_stations" :key="index+'reference_stations'" :value="item.rf_code" >{{ item.rf_code_descr}} ({{ item.rf_location }})</b-form-select-option>
              
              </b-form-select>
              
            </div>
            <div class="mt-2">
              <h6>Description</h6>
              <div v-if="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id" class="p-2 shadow-sm" style="background-color: #e9ecef;border-radius: 5px;">
                  {{ clicked_Incident.incident_description }}
              </div>
              <div v-else>
              <b-form-textarea
                id="textarea"
                v-model="clicked_Incident.incident_description"
                placeholder="Description"
                size="sm"
                class="shadow-sm"
              ></b-form-textarea>
              </div>
            </div>
            <div class="mt-2">
              <h6>Type</h6>
              
              <b-form-select v-model="clicked_Incident.incident_type" class="mb-1 shadow-sm" :disabled="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id">
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="undefined" disabled>-- Please select an option --</b-form-select-option>
                </template>

                <!-- These options will appear after the ones from 'options' prop -->
                <b-form-select-option v-for="(item,index) in luts.incidents_type_expiratrion" :key="index+'incidents_type_expiratrion'" :value="item.type_id" >{{ item.type_description}} ({{ parseInt(item.type_expiration_minutes /60) }} hours)</b-form-select-option>
               
              </b-form-select>
             
            </div>
            <div class="mt-2 ">
              <div class="d-flex align-items-center">
              <h6 class="m-0 pr-2">Acknowledged:</h6>
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="acknowledged_status"
                  name="checkbox-1"
                  :value="1"
                  :unchecked-value="0"
                  :disabled="this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0"></b-form-checkbox>
              </div>
              <div class="d-flex align-items-center">
                <b-form-input class="col-6 shadow-sm" :type="'date'" v-model="acknowledged_date" :disabled="!acknowledged_status ||  (clicked_Incident.incident_type_acknowledged_time ? true:false )"></b-form-input>
                <b-form-input class="col-6 shadow-sm" :type="'time'" v-model="acknowledged_time" :disabled="!acknowledged_status || (clicked_Incident.incident_type_acknowledged_time ? true:false )"></b-form-input>
              </div>
            </div>
            <div class="mt-3 d-flex justify-content-end ">
              <b-button block variant="primary" @click="saveInfo" v-if="this.clicked_Incident.incident_status===1 || this.clicked_Incident.incident_status===0"><i class="fa-solid fa-floppy-disk"></i> Save</b-button>
            </div>
            <div class="mt-3 " v-if="clicked_Incident.incident_status!==0">
              <div class="d-flex align-items-center mb-1">
                <h6 class="m-0 pr-2">Actions:</h6>
              </div>
              <div>
                  <div class="row m-0 mb-3" v-if="this.clicked_Incident.incident_status!==3">
                    <div class="col-1 p-0 ">
                        <div class="d-flex justify-content-end">
                          <div class="rounded-pill p-2 text-center shadow-sm" style="background-color: #45baff;">
                              <h6 class="m-0 font-weight-bold">{{arxika}}</h6> 
                          </div>
                        </div>
                    </div>
                    <div class="col-11 p-0 pl-2 d-flex">
                      <b-form-textarea
                        id="textarea-small"
                        size="sm"
                        placeholder="Write an action..."
                        class="shadow-sm"
                        v-model="action_comment"
                      ></b-form-textarea>
                      <b-button class="shadow-sm" style="font-size:20px" variant="primary" size="sm" :disabled="!action_comment" title="Save" @click="saveAction">></b-button>
                    </div>
                  </div>
                  <div class="row m-0 mb-3" v-for="(action,index) in actions_list" :key="index+'action'">
                    <div class="col-1 p-0 ">
                        <div class="d-flex justify-content-end">
                          <div class="rounded-pill p-2 text-center shadow-sm" style="background-color: #45baff;">
                              <h6 class="m-0 font-weight-bold">{{action.user.first_name.charAt(0).toUpperCase()+""+action.user.last_name.charAt(0).toUpperCase()}}</h6> 
                          </div>
                        </div>
                    </div>
                    <div class="col-11 p-0 pl-2">
                      <div>
                      <p class="m-0" style="font-size: 12px;">
                        <strong>{{ action.user.first_name }} {{ action.user.last_name }}</strong>  {{ new Date(action.action_time).toLocaleString("en-US", {timeZone: "Europe/Athens"})}}
                      </p>
                      <div class="d-flex">
                        <div class="shadow-sm w-100 p-2 " style="background-color: #e9ecef;border-radius: 5px;">{{action.action_description}}</div>
                      
                      </div>
                    </div>
                     
                    </div>
                  </div>
              </div>
            </div>
            <div class="mt-3" v-if="clicked_Incident.incident_status===2">
              <!--<b-button class="shadow-sm" block variant="success" title="Save" @click="moveItem(3)"><i class="fa-solid fa-check px-2"></i>Finished</b-button>-->
              <b-form-checkbox
                id="checkbox-55"
                v-model="finished_checkbox"
                name="checkbox-55"
                @change="finished_checkbox ? moveItem(3) : null"
                size="lg"
              >
              Close Incident
              </b-form-checkbox>
            </div>

        </div>
      </div>
    </b-modal>

    <b-modal ref="finished-modal" hide-footer  size="xl" title="Finished List">
      <div class="row p-2">
        <b-table hover responsive :items="finished_all_list" :fields="fields_finished_all_list" class="col-12 col-md-6 card shadow-sm" @row-clicked="onRowClick"  selectable :select-mode="'single'">
          <template #cell(incident_cat_title)="data">
            {{ data.value.category_description }}
          </template>
          <template #cell(incident_type)="data">
            {{ luts['incidents_type_expiratrion'][data.value-1].type_description }} ({{ parseInt(luts['incidents_type_expiratrion'][data.value-1].type_expiration_minutes/60) }} hours)
          </template>
        </b-table>
        <div class="col-12 col-md-6 h-100" style="position: sticky; top: 0;">
          <div v-if="clicked_Incident">
          
            <div class="shadow-sm card p-3" v-if="!isMobile" >
                <div>
                  <div class="w-100 text-center"><strong class="text-info">#{{ clicked_Incident.incident_id }}</strong></div>
                  
                  <h6>Title</h6>
                  <div v-if="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id" class="p-2 shadow-sm" style="background-color: #e9ecef;border-radius: 5px;">
                      {{ clicked_Incident.incident_cat_title.category_description }}
                      
                  </div>
                  <div v-else>
                    <b-form-select v-model="clicked_Incident.incident_cat_title.category_id" class="mb-1 shadow-sm">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="undefined" disabled>-- Please select an option --</b-form-select-option>
                    </template>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option  v-for="(item,index) in luts.lut_incidents_categories" :key="index+'lut_incidents_categories'" :value="item.category_id" >{{ item.category_description}}</b-form-select-option>
                    
                  </b-form-select>
                  </div>
                  
                </div>
                <div class="mt-2">
  
                  <h6>Reference Station</h6>
                
                    <b-form-select v-model="clicked_Incident.reference_station_id" class="mb-1 shadow-sm" :disabled="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="undefined" disabled>-- Please select an option --</b-form-select-option>
                    </template>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option  v-for="(item,index) in luts.reference_stations" :key="index+'reference_stations'" :value="item.rf_code" >{{ item.rf_code_descr}} ({{ item.rf_location }})</b-form-select-option>
                  
                  </b-form-select>
                  
                </div>
                <div class="mt-2">
                  <h6>Description</h6>
                  <div v-if="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id" class="p-2 shadow-sm" style="background-color: #e9ecef;border-radius: 5px;">
                      {{ clicked_Incident.incident_description }}
                  </div>
                  <div v-else>
                  <b-form-textarea
                    id="textarea"
                    v-model="clicked_Incident.incident_description"
                    placeholder="Description"
                    size="sm"
                    class="shadow-sm"
                  ></b-form-textarea>
                  </div>
                </div>
                <div class="mt-2">
                  <h6>Type</h6>
                  
                  <b-form-select v-model="clicked_Incident.incident_type" class="mb-1 shadow-sm" :disabled="(this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0) || current_user.id!==clicked_Incident.user.id">
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="undefined" disabled>-- Please select an option --</b-form-select-option>
                    </template>

                    <!-- These options will appear after the ones from 'options' prop -->
                    <b-form-select-option v-for="(item,index) in luts.incidents_type_expiratrion" :key="index+'incidents_type_expiratrion'" :value="item.type_id" >{{ item.type_description}} ({{ parseInt(item.type_expiration_minutes /60) }} hours)</b-form-select-option>
                    
                  </b-form-select>
                  
                </div>
                <div class="mt-2 ">
                  <div class="d-flex align-items-center">
                  <h6 class="m-0 pr-2">Acknowledged:</h6>
                  <b-form-checkbox
                      id="checkbox-1"
                      v-model="acknowledged_status"
                      name="checkbox-1"
                      :value="1"
                      :unchecked-value="0"
                      :disabled="this.clicked_Incident.incident_status!==1 && this.clicked_Incident.incident_status!==0"></b-form-checkbox>
                  </div>
                  <div class="d-flex align-items-center">
                    <b-form-input class="col-6 shadow-sm" :type="'date'" v-model="acknowledged_date" :disabled="!acknowledged_status ||  (clicked_Incident.incident_type_acknowledged_time ? true:false )"></b-form-input>
                    <b-form-input class="col-6 shadow-sm" :type="'time'" v-model="acknowledged_time" :disabled="!acknowledged_status || (clicked_Incident.incident_type_acknowledged_time ? true:false )"></b-form-input>
                  </div>
                </div>
                <div class="mt-3 d-flex justify-content-end ">
                  <b-button block variant="primary" @click="saveInfo" v-if="this.clicked_Incident.incident_status===1 || this.clicked_Incident.incident_status===0"><i class="fa-solid fa-floppy-disk"></i> Save</b-button>
                </div>
                <div class="mt-3 " v-if="clicked_Incident.incident_status!==0">
                  <div class="d-flex align-items-center mb-1">
                    <h6 class="m-0 pr-2">Actions:</h6>
                  </div>
                  <div>
                      <div class="row m-0 mb-3" v-if="this.clicked_Incident.incident_status!==3">
                        <div class="col-1 p-0 ">
                            <div class="d-flex justify-content-end">
                              <div class="rounded-pill p-2 text-center shadow-sm" style="background-color: #45baff;">
                                  <h6 class="m-0 font-weight-bold">{{arxika}}</h6> 
                              </div>
                            </div>
                        </div>
                        <div class="col-11 p-0 pl-2 d-flex">
                          <b-form-textarea
                            id="textarea-small"
                            size="sm"
                            placeholder="Write an action..."
                            class="shadow-sm"
                            v-model="action_comment"
                          ></b-form-textarea>
                          <b-button class="shadow-sm" style="font-size:20px" variant="primary" size="sm" :disabled="!action_comment" title="Save" @click="saveAction">></b-button>
                        </div>
                      </div>
                      <div class="row m-0 mb-3" v-for="(action,index) in actions_list" :key="index+'action'">
                        <div class="col-1 p-0 ">
                            <div class="d-flex justify-content-end">
                              <div class="rounded-pill p-2 text-center shadow-sm" style="background-color: #45baff;">
                                  <h6 class="m-0 font-weight-bold">{{action.user.first_name.charAt(0).toUpperCase()+""+action.user.last_name.charAt(0).toUpperCase()}}</h6> 
                              </div>
                            </div>
                        </div>
                        <div class="col-11 p-0 pl-2">
                          <div>
                          <p class="m-0" style="font-size: 12px;">
                            <strong>{{ action.user.first_name }} {{ action.user.last_name }}</strong>  {{ new Date(action.action_time).toLocaleString("en-US", {timeZone: "Europe/Athens"})}}
                          </p>
                          <div class="d-flex">
                            <div class="shadow-sm w-100 p-2 " style="background-color: #e9ecef;border-radius: 5px;">{{action.action_description}}</div>
                          
                          </div>
                        </div>
                          
                        </div>
                      </div>
                  </div>
                </div>
                <div class="mt-3" v-if="clicked_Incident.incident_status!==3 && clicked_Incident.incident_status!==0">
                  <!--<b-button class="shadow-sm" block variant="success" title="Save" @click="moveItem(3)"><i class="fa-solid fa-check px-2"></i>Finished</b-button>-->
                  <b-form-checkbox
                    id="checkbox-55"
                    v-model="finished_checkbox"
                    name="checkbox-55"
                    @change="finished_checkbox ? moveItem(3) : null"
                    size="lg"
                  >
                    Close Incident
                  </b-form-checkbox>
                </div>

            </div>
          </div>

        </div>
    </div>
    </b-modal>
    <b-modal id="modal-profile" title=""  hide-footer size="xl">
          <Profile />
    </b-modal>
  </div>
  
 </div>
</template>


<script>
//import L from "leaflet";
import axios_django from "../axios-auth";

import draggable from 'vuedraggable';
import Profile from './Profile.vue'

export default {
  components: {
    //VueSlider,
    //VJstree,
    //'rs-panes':ResSplitPane,
    draggable,
    Profile
  },
  data() {
    return {
      filter_incidents_type_expiratrion:null,
      finished_checkbox:false,
      Incidentes_list:[],
      ongoing_list:[],
      finished_list:[],
      finished_all_list:[],
      timeDifference:null,
      clicked_Incident:null,
      luts:null,
      socket: null,
      message: '',
      messages: [],
      acknowledged_date: this.getCurrentDate(), 
      acknowledged_time: this.getCurrentTime(),
      acknowledged_status: 0,
      dragging: false, // Dragging state
      actions_list:[],
      arxika:null,
      action_comment:null,
      current_user:null,
      isMobile: false,
      notification_num:0,
      fields_finished_all_list: ['incident_id','incident_cat_title','incident_description', 'incident_finished_time' ],
      //num:0,
    }
  },
  computed:{
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        ghostClass: "ghost"
      };
    },
    dragOptions_finished(){
      return {
        animation: 0,
        group: "description",
        ghostClass: "ghost",
        draggableEnabled: false,
      }
    }
  },
 
  mounted(){
    var protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
    //console.log(protocol)
    //let url = protocol+`//4.tcp.eu.ngrok.io:14537/ws/socket-server/`
    //let url = `wss://9f8d-37-6-81-137.ngrok-free.app/ws/chat/`
    let url = protocol+`//hepos-incident-backend.geotech.gr/ws/chat/test/`
    //let url = protocol+`//localhost:8000/ws/chat/test/`

    this.chatSocket = new WebSocket(url)
    var v=this
    this.chatSocket.onmessage = function(e){
        let data = JSON.parse(e.data)
        console.log('chatSocket',data)
        data=data.data
        data=JSON.parse(data)
        //v.Incidentes_list=data.data
        //v.syncMessagesLater()
        v.createLists(data.data)

        ///v.$refs.soundEffect.play();
        v.$Progress.finish()
        v.$message.success("Success", {
          duration: 5000
        });

    }
    this.$nextTick(() => {
  
      //this.$refs.map1.mapObject.sync(this.$refs.map2.mapObject)
      //this.$refs.map2.mapObject.sync(this.$refs.map1.mapObject)
    });
  },
  async created(){
    //var audio = new Audio('http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3')
    //console.log(audio)
    //audio.play()

    this.isMobile = this.isMobileDevice();
    console.log(this.isMobile)
    console.log(new Date())
    var user = localStorage.getItem("user");
    console.log(user)
    user= JSON.parse(user)
    this.current_user=user
    let first_letter = user.first_name.charAt(0);
    let second_letter = user.last_name.charAt(0);
    this.arxika=first_letter.toUpperCase()+""+second_letter.toUpperCase()
    
    //this.calculateTimeDifference()
    var v=this
    //var list
    this.$Progress.start()

    axios_django.get('incidents-list/').then((response)=>{
      //list=response.data
      console.log(response.data)
      
      this.$Progress.finish()

      this.createLists(response.data)

      
    
    })

    /*
    axios_django.get('incidents-list/',{params:{status:2}}).then((response)=>{
      console.log(response.data)
      v.ongoing_list=response.data
      for(var tt=0; tt<v.ongoing_list.length; tt++){
          v.ongoing_list[tt].count_exp_time=this.calculateTimeDifference(v.ongoing_list[tt].incident_expired_time)
      }
    })

    axios_django.get('incidents-list/',{params:{status:3}}).then((response)=>{
      console.log(response.data)
      v.finished_list=response.data
  
    })
    */
    axios_django.get('lut-list/').then((response)=>{
      console.log(response.data)
      v.luts=response.data
  
    })
    setInterval(()=>{
        console.log('here')
        for(var i=0; i<v.Incidentes_list.length; i++){
          //this.Incidentes_list[tt].count_exp_time=this.calculateTimeDifference(v.Incidentes_list[tt].incident_expired_time)
          this.$set(this.Incidentes_list, i, { ...this.Incidentes_list[i], count_exp_time: this.calculateTimeDifference(this.Incidentes_list[i].incident_expired_time) });
        }
        for(var mm=0; mm<v.ongoing_list.length; mm++){
          //this.Incidentes_list[tt].count_exp_time=this.calculateTimeDifference(v.Incidentes_list[tt].incident_expired_time)
          this.$set(this.ongoing_list, mm, { ...this.ongoing_list[mm], count_exp_time: this.calculateTimeDifference(this.ongoing_list[mm].incident_expired_time) });
        }
        //console.log(v.Incidentes_list)
      }, 60000); // Recalculate every minute
  },
  methods:{
    changeTitle(){
      //console.log(this.clicked_Incident.incident_cat_title.category_id)
      //console.log(this.luts)
      var cat_type
      for(var ff=0; ff<this.luts.lut_incidents_categories.length; ff++){
        if(this.clicked_Incident.incident_cat_title.category_id===this.luts.lut_incidents_categories[ff].category_id){
          cat_type=this.luts.lut_incidents_categories[ff].category_type
        }
      }
      
      this.filter_incidents_type_expiratrion=this.luts.incidents_type_expiratrion.filter((item)=>item.type_description.includes(cat_type))
      //console.log(this.luts)
    },
    onRowClick(item,index) {
      // Handle row click event
      console.log('Row clicked:', item);
      this.getInfo(item)
      this.finished_all_list[index].isSelected = !this.finished_all_list[index].isSelected;
    },
    ViewAllFinished(){
      var v=this 
      axios_django.get('incidents-list/',{params:{status:3}}).then((response)=>{
        //console.log(response.data)
        v.$refs['finished-modal'].show()
        v.finished_all_list=response.data
        v.finished_all_list.forEach(item => {
          // Initialize isSelected property for each item to false
          item.isSelected = false;
        });
      })
    },
    saveAction(){
     

      
      var user = localStorage.getItem("user");
      user= JSON.parse(user)
      var form={}

      //console.log(currentDateInGreece); // This will display the current date and time in the timezone of Greece
    
      form.action_description=this.action_comment
      form.user_id=user.id
    
      form.incident_id=this.clicked_Incident.incident_id
      form.action_time=new Date()
      form.action_id=null
      var v=this
      console.log(form)
      axios_django.post('post/actions/',{form:form}).then(res=>{
        console.log(res.data)
        //this.getActions(this.clicked_Incident)
        v.$message.success("Success", {
          duration: 5000
        });
      }).catch((error)=>{
        console.log(error)
        v.$message.error("Error", {
          duration: 5000
        });
      })

      if(this.clicked_Incident.incident_status===1){
        this.moveItem(2)
      }
      this.action_comment=null
    
    },
    moveItem(status){
        this.$Progress.start()

     // if(event.added){
        //console.log(event)
        //console.log(status)
        var form=this.clicked_Incident //event.added.element
        console.log(form)
        form.incident_status=status
        if(status===3){
          form.incident_finished_time=new Date()
        }
        var acknowledged_date_time=new Date(this.acknowledged_date+' '+this.acknowledged_time)
        console.log(acknowledged_date_time)

        if(!form.incident_type_acknowledged_time){
          form.incident_type_acknowledged_time=acknowledged_date_time
        }
        this.chatSocket.send(JSON.stringify({
          'form':JSON.stringify(form)
      }))

      if(status){
        this.clicked_Incident=null
        if(this.isMobile){
          this.$refs['my-modal'].hide()
        }
      }
      this.finished_checkbox=false
    },
    createLists(list){
      var v=this
      v.Incidentes_list=[]
      v.ongoing_list=[]
      v.finished_list=[]

      v.Incidentes_list=list['incident']
      v.ongoing_list=list['ongoing']
      v.finished_list=list['finished']
      v.notification_num=0;
      for(var kl=0; kl<v.Incidentes_list.length; kl++){
        if(v.Incidentes_list[kl].incident_status===0){
          v.notification_num++
        }
        
      }


        for(var tt=0; tt<v.Incidentes_list.length; tt++){
          v.Incidentes_list[tt].count_exp_time=this.calculateTimeDifference(v.Incidentes_list[tt].incident_expired_time)
        }
        for(var hh=0; hh<v.ongoing_list.length; hh++){
          v.ongoing_list[hh].count_exp_time=this.calculateTimeDifference(v.ongoing_list[hh].incident_expired_time)
        }
    },
    saveInfo(){
      
      console.log(this.clicked_Incident)
      var currentDate = this.clicked_Incident.incident_start_time ? new Date(this.clicked_Incident.incident_start_time) : new Date();
      //console.log(currentDate)
      var form=this.clicked_Incident
      //form.incident_type_id=this.clicked_Incident.incident_type
      //form.incident_cat_title_id=this.clicked_Incident.incident_cat_title.category_id
      
      form.incident_status=this.acknowledged_status
      var user = localStorage.getItem("user");
      user= JSON.parse(user)
      form.incident_acknowledge_user_id=user.id
      //console.log(this.acknowledged_status)
      
      //form.user_id=this.clicked_Incident.user.id
      //form.incident_id=incident_id

      if(form.incident_id==undefined){
        form.incident_id=null
      }

      
      var acknowledged_date_time=new Date(this.acknowledged_date+' '+this.acknowledged_time)
      //console.log(acknowledged_date_time)
      console.log('acknowledged_status',this.acknowledged_status)
      if(this.acknowledged_status  ){
        if(!this.clicked_Incident.incident_type_acknowledged_time){
          form.incident_type_acknowledged_time=acknowledged_date_time
        }
       
       
      }
      
      for(var rr=0; rr<this.luts.incidents_type_expiratrion.length; rr++){
       
       if(form.incident_type===this.luts.incidents_type_expiratrion[rr].type_id){
         console.log(this.luts.incidents_type_expiratrion[rr].type_expiration_minutes)
         form.incident_expired_time=currentDate.setMinutes(currentDate.getMinutes() + this.luts.incidents_type_expiratrion[rr].type_expiration_minutes)
         form.incident_expired_time=new Date(form.incident_expired_time)
       }
     }
     form.incident_start_time=this.clicked_Incident.incident_start_time ? new Date(this.clicked_Incident.incident_start_time) : new Date();
      console.log(form.incident_start_time)
      //axios_django.post('post/incident-list/',{form:this.clicked_Incident})
      this.$Progress.start()
      this.chatSocket.send(JSON.stringify({
                'form':JSON.stringify(form)
      }))

      if(!form.incident_id){
        this.clicked_Incident=null
        if(this.isMobile){
          this.$refs['my-modal'].hide()
        }
      }
    },
    addIncident(){
      if(this.isMobile){
        this.$refs['my-modal'].show()
      }
      var user = localStorage.getItem("user");
      user= JSON.parse(user)
      this.clicked_Incident={incident_cat_title:{},incident_status:0,user_id:user.id,user:{id:user.id}}
      this.acknowledged_date=this.getCurrentDate()
      this.acknowledged_time=this.getCurrentTime()
      this.acknowledged_status=0
      this.actions_list=[]
    },
    getInfo(item){
      if(this.isMobile){
        this.$refs['my-modal'].show()
      }
      
      console.log(item)
      this.clicked_Incident=item
      if(item.incident_type_acknowledged_time){
        var date=item.incident_type_acknowledged_time.split('T')
        var time=date[1].split(':')
        time=(parseInt(time[0])+2)+':'+time[1]
        this.acknowledged_date=date[0]
        this.acknowledged_time=time
      }else{
        this.acknowledged_date=this.getCurrentDate()
        this.acknowledged_time=this.getCurrentTime()
      }
      this.acknowledged_status=this.clicked_Incident.incident_status > 0 ? 1:0

      this.getActions(item)
    },
    getActions(item){
      //console.log(item)
      axios_django.get('get/actions/',{params:{incident_id:item.incident_id}}).then(res=>{
        console.log(res.data)
        this.actions_list=res.data
      })
    },
    calculateTimeDifference(incident_expired_time) {
      const startTime = new Date();
      const expiredTime = new Date(incident_expired_time);
      if(startTime>expiredTime){
        return `Expired`
      }
      let diff = expiredTime - startTime;
      //console.log(startTime)
      //console.log(expiredTime)
      // Convert difference to positive if negative
      diff = Math.abs(diff);

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

      this.timeDifference = `${hours} hours, ${minutes} minutes`;
      return `${hours} hours, ${minutes} minutes`
      //console.log(this.timeDifference)
    },
    getCurrentTime() {
      const today = new Date();
      let hours = today.getHours();
      let minutes = today.getMinutes();

      // Ensure hours and minutes are in two-digit format
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;

      return `${hours}:${minutes}`;
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let day = today.getDate();

      // Ensure month and day are in two-digit format
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;

      return `${year}-${month}-${day}`;
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    Logout(){
      localStorage.clear()
      this.$router.push('/')
    }
    
    
  }
  
 

};
</script>

<style >
body{

  font-family: 'Manrope', sans-serif!important;
  font-size: 14px
}
  
.card-geot{
  border-radius: 10px;
  background: #e3e3e3;
}
.card-geot-element{
  border-radius: 5px;
  background: #ffffff;
  padding: 10px;
}
.tree-default .tree-node {
    margin-left: 10px!important;
}
.tree-default .tree-wholerow-clicked {
    background: '#fff0'!important;
}
.tree-default .tree-disabled > .tree-icon {
   opacity: 1!important;
  filter: 0 !important;
  filter: rgba(128, 128, 128, 0) !important;
  -webkit-filter: grayscale(0%) !important;
}
.tree-default .tree-disabled{
  color:inherit!important;
}

.tree-default .tree-disabled > .tree-checkbox {
  opacity: 0.05!important;
}
.tree-default .tree-wholerow-clicked {
    background: #fff0!important;
}
.tree-wholerow-hovered{
  background: #fff0!important;
}
.bottomleft-control {
  background: #6c757d;
    border-radius: 0 20px 0 0;
    box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
}
.logo-perifereia {
  width: 20vh;
}
.chart-div{
  border-radius: 20px 0 0 0;
  box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
  width:35%;
}
.btn-settings{
  border-radius: 0 20px 20px 0!important;
  box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
}
.legend-div{
  position:absolute;
  bottom:8px;
  right:5px;
  z-index: 1001;
}
@media screen and (max-width: 730px) {
  .chart-div{
    border-radius: 0 0 0 0;
    box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
    width:100%;
  }
  .logo-perifereia {
  width: 15vh;
}

.card-body {
   
    padding: 0.4rem!important;
}
}
hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
/*.tree-children{
  padding:10px!important;
}*/

::-webkit-scrollbar {
    width: 5px;
    height:5px
}
::-webkit-scrollbar-thumb {
    background: #07a4ff;
    border-radius: 20px;
}
::-webkit-scrollbar-track {
    background-color: #9e9e9e;
    border-radius: 20px;    
}
.leaflet-control{
  background:#003b5dbf!important;
  border-radius:20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;
  padding:10px;
  color:white
}
.leaflet-touch .leaflet-bar a{
  background: #fff0!important;
  color: #fff;
  width: 25px;
  height: 25px;

}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: 0px solid rgba(0,0,0,0.2)!important;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;
}
.leaflet-touch .leaflet-control-layers-toggle {
    width: 30px;
    height: 30px;
}
.leaflet-control-geosearch a.leaflet-bar-part:after {
    top: 16px;
    left: 16px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px solid #fff;
}
.leaflet-control-geosearch a.leaflet-bar-part:before {
    top: 29px;
    left: 26px;
    width: 8px;
    border-top: 2px solid #fff;
    transform: rotateZ(45deg);
    
}
.fa-map-marker{
  font-size:20px;
  color:#fff
}
.leaflet-tooltip-own{
  background:#ffffff30;
  border: 0px solid #ffffff30;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: transparent;
    content: "";
    }

.geosearch{
  color:black;
  z-index:9000;
}
.col-lg-12{
  padding-right: 0px!important;
  padding-left: 0px!important;
}
.col-lg-6{
  padding-right: 0px!important;
  padding-left: 0px!important;
}
.col-lg-7{
  padding-right: 0px!important;
  padding-left: 0px!important;
}
.col-lg-5{
  padding-right: 0px!important;
  padding-left: 0px!important;
}



.gradient-box {
  
  background: linear-gradient(45deg, #810170, #001691, rgb(0, 100, 95)); /* Initial gradient colors */
  background-size: 600% 600%;
  animation: changeGradient 20s infinite;
}

@keyframes changeGradient {
  0% {
    background-position: 0% 50%; /* Initial position */
  }
  50% {
    background-position: 100% 50%; /* Final position */
  }
  100% {
    background-position: 0% 50%; /* Back to initial position */
  }
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip{
  width: 380px;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
    text-align: left!important;;
}

</style>



<!--
  {
                "text": "M.A. θερμών ημερών 1981-2000",
                "description":"Μέσος αριθμός πολύ θερμών ημερών (>35°C) την περίοδο αναφοράς (1981-2000)",
                "type_of_data":"geojson",
                "loaded":false,
                "data_column":"Be46",
                "value":[{"api":"pespka/klimatikes_allages/","params": {"db_name":"be46"}}],
                "legend":{"tooltip":{"show":true,"tooltip_column":"mean","permanent":true},"column":"mean", "value":[[0,114],[114,117],[117,124],[124,130],[130,132]],"color":["#ffcccc","#ff9e8f","#fa725a","#ed432d","#db0000"],"text":["0-113","114-117","118-124","125-130","131-132"],"title":"Μέσος αριθμός πολύ θερμών ημερών (>35°C) την περίοδο αναφοράς (1981-2000)","weight":0.5,"weight_color":"black"}
            },
-->